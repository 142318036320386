import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/bulma/BulmaLayout';
import SectionWrapper from '../components/bulma/BulmaSectionWrapper';
import PageTitle from '../components/PageTitle';
import LodgingSpecial from '../components/lodging/LodgingSpecial';
import site from '../../data/siteConf';

const page = site.basaltFlyFishingPage;

const BasaltFlyFishingPage = () => (
  <Layout>
    <Helmet>
      <title>
        {site.siteTitle} - {page.title}
      </title>
      <meta name="description" content={page.metaDescription} />
    </Helmet>
    <SectionWrapper>
      <PageTitle text="Fly Fishing" />
      <div className="columns">
        <div className="column">
          <div className="img-container">
            <figure className="image">
              <img src={page.imageRfrIsland.src} alt={page.imageRfrIsland.caption} />
            </figure>
          </div>
          <p>
            Basalt is centrally located, in the Roaring Fork Valley at the confluence of the Frying Pan and Roaring Fork
            Rivers. Both of these rivers are designated as Gold Medal Waters by the Colorado Wildlife Commission. Gold
            Medal waters are defined as being able to produce 60 pounds of trout and at least 12 fish, 14 inches or
            larger per acre. Only 322 miles of of Colorado's 9000 miles of trout streams, and three lakes, are
            designated as "Gold Medal".
          </p>
          <p>
            Basalt is a great destination for fishermen who want to enjoy a year round fishery, great location, easy
            access to the rivers and the pleasant environs of the Roaring Fork Valley restaurants, shopping and
            accommodation. Many great fishing locations are in remote areas which provide limited amenities. In
            contrast, Basalt is an ideal location for a fisherman who enjoys his fishing and has a family more
            interested in other pursuits.
          </p>
          <figure className="image is-pulled-right">
            <img src={page.imageFprRun.src} alt={page.imageFprRun.caption} />
          </figure>
          <p>
            With the Frying Pan, Roaring Fork and Colorado Rivers we have in excess of 70 miles of excellent accessible
            water to enjoy. The Frying Pan is a wading river while the Roaring Fork can be floated from up near Aspen
            almost 40 miles to Glenwood. The Colorado can be floated from the Grizzly put in all the way to Rifle and
            Silt. There is excellent public access on all rivers and details are available at all local fly shops.
          </p>
          <p>
            One of the great attractions of our area in winter is the ability to ski a world class mountain at Aspen or
            Snowmass in the morning and fish in a world class river in the afternoon. The lower Frying Pan is fed from
            Ruedi Dam and being a bottom release dam this means that the water released in winter is close to 40
            degrees. Therefore it will maintain an active midge biomass enabling the fish to feed even in the coldest
            wintery conditions. That is the reason there is excellent dry fly fishing throughout the winter.
          </p>
          <p>
            In addition, during summer, the bottom releases ensure that the water remains cold slowing down the rate of
            maturity of mayflies such as the green drakes. As a consequence where a drake hatch will only last a short
            time in a freestone river, we enjoy drakes hatching on the Frying Pan from later July through to late
            September providing the great drake hatch experience for which the Frying Pan is so famous.
          </p>
          <p>
            The other relatively unique feature is the release from the dam of the Mysis shrimp which is an excellent
            protein source bringing the fish fast growth and large size. The mysis was introduced when the dam was built
            as a food source to support an unsuccessful experiment to support introduced Kokanee salmon in Ruedi. The
            experiment failed but the mysis lives on providing a food source which has produced a lot of massive trout
            at the base of the dam outflow, commonly known as the "toilet bowl".
          </p>
          <figure className="image">
            <img src={page.imageFprPool.src} alt={page.imageFprPool.caption} />
          </figure>
        </div>
      </div>
    </SectionWrapper>
  </Layout>
);

export default BasaltFlyFishingPage;
